/*eslint-disable*/
import zIndex from "@mui/material/styles/zIndex";
import { createSlice } from "@reduxjs/toolkit";
import CustomHeader from "./CustomHeader";
import CustomHeader2 from "./CustomHeader2";
import UnrealizedPnlCellRenderer from "components/PositionsGrid/container/UnrealizedPnlCellRenderer";
import { strategyLists } from "../presentation/PnlGrid";
import "../presentation/PnlTable.css";
import ButtonRenderer from "./ButtonRenderer";

const realizedPnlGetter = (params) => {
  const formattedValue = Math.round(Number(params.value)).toLocaleString(
    "en-IN",
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  );
  return formattedValue;
};
const unRealizedPnlGetter = (params) => {
  const formattedValue = Math.round(Number(params.value)).toLocaleString(
    "en-IN",
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  );
  return formattedValue;
};

const initialState = {
  filterText: "",
  showPnlChart: false,
  closePnlChart: true,
  pnlChartData: null,
  columnOptions: [
    {
      headerName: "Date",
      field: "date",
      headerClass: "align-left dateHeader paddingZero",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      minWidth: 90,
      flex: 1,
      padding: "0",
      sort: null,
    },
    {
      headerName: "Trading Symbol",
      field: "tradingSymbol",
      filter: true,
      filter: "agTextColumnFilter",
      headerClass: "align-left fitContentHeader left-aligned-header-padding",
      cellClass: "align-left",
      filter: "agSetColumnFilter",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      minWidth: 140,
      sort: null,
      cellRenderer: ButtonRenderer,
      cellRendererParams: (params) => ({
        rowData: params.data,
        value: params.value,
      }),
      flex: 1,
    },
    {
      headerName: "Broker",
      field: "positionCompositeKey.broker",
      headerClass:
        "align-left custom-header-padding left-aligned-header-padding",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      minWidth: 110,
      sort: null,
      flex: 1,
    },
    {
      headerName: "Strategy Name",
      field: "strategyLists",
      headerClass:
        "align-left custom-header-padding left-aligned-header-padding",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      minWidth: 120,
      sort: null,
      valueGetter: (params) => {
        const strategyId = params?.data?.positionCompositeKey?.strategyId;
        // Return the corresponding strategy if it exists
        return strategyId && strategyLists
          ? strategyLists.filter((str) => str.strategy_id === strategyId)[0]
              ?.strategy_name
          : "";
      },
      flex: 1,
    },
    {
      headerName: "Realized  LTD",
      field: "realizedLTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: realizedPnlGetter,
      //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 110,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: realizedPnlGetter,
      },
      flex: 1,
    },
    {
      headerName: "Unrealized  LTD",
      field: "unRealizedLTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: unRealizedPnlGetter,
      //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      sort: null,
      minWidth: 140,
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: unRealizedPnlGetter,
      },
      flex: 1,
    },
    {
      headerName: "Dividend   LTD",
      field: "dividend",
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 110,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: unRealizedPnlGetter,
      },
      flex: 1,
    },
    {
      headerName: "Realized DTD",
      field: "realizedDTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: realizedPnlGetter,
      //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 110,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: realizedPnlGetter,
      },
      flex: 1,
    },
    {
      headerName: "Unrealized DTD",
      field: "unRealizedDTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: unRealizedPnlGetter,
      //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 140,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: unRealizedPnlGetter,
      },
      flex: 1,
    },

    {
      headerName: "Realized MTD",
      field: "realizedMTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: realizedPnlGetter,
      //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 110,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: realizedPnlGetter,
      },
      flex: 1,
    },
    {
      headerName: "Unrealized MTD",
      field: "unRealizedMTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: unRealizedPnlGetter,
      //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 140,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: unRealizedPnlGetter,
      },
      flex: 1,
    },

    {
      headerName: "Realized YTD",
      field: "realizedYTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: realizedPnlGetter,
      //headerComponentParams: { type: 'realized', headerName: "Realized Pnl"},
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 110,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: realizedPnlGetter,
      },
      flex: 1,
    },
    {
      headerName: "Unrealized YTD",
      field: "unRealizedYTDPnl",
      //headerComponent: CustomHeader,
      valueFormatter: unRealizedPnlGetter,
      //headerComponentParams: { type: 'unRealized', headerName: "Unrealized Pnl" },
      headerClass: "ag-right-aligned-header right-aligned-header-padding",
      headerStyle: { padding: "0 20px 0 0 !important" },
      cellStyle: { justifyContent: "end", padding: "0 20px 0 0" },
      minWidth: 130,
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellRendererParams: {
        valueFormatter: unRealizedPnlGetter,
      },
      flex: 1.5,
    },
  ],
};

const PnlTableOptionSlice = createSlice({
  name: "pnlTableOption",
  initialState,
  reducers: {
    setTableOptionsData(state, action) {
      state.columnOptions = action.payload;
    },

    setFilterText(state, action) {
      state.filterText = state.filterText = /^[0-9]/.test(action.payload)
        ? action.payload.substring(1) // Remove the first character if it's a number
        : action.payload.replace(/[^a-zA-Z0-9]/g, ""); //Allow only number and alphabets
    },
  },
});
export const { setTableOptionsData, setFilterText } =
  PnlTableOptionSlice.actions;
export default PnlTableOptionSlice.reducer;
