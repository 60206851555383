import { setShowPnlChart } from "components/PnlChart/PnlChartSlice";
import { setPnlChartData } from "components/PnlChart/PnlChartSlice";
import { LuChartNoAxesCombined } from "react-icons/lu";
import { useDispatch } from "react-redux";
const ButtonRenderer = ({ rowData, value }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <span className="value-column">{value}</span>
      {value && (
        <div className="button-container">
          <span>{value}</span>
          <span
            className="right-1 absolute cursor-pointer"
            onClick={() => {
              dispatch(setPnlChartData(rowData));
              dispatch(setShowPnlChart(true));
            }}
          >
            {/* <FaChartBar size={16} color="#60a5fa" /> */}
            <LuChartNoAxesCombined size={16} color="#60a5fa" />
          </span>
        </div>
      )}
    </div>
  );
};

export default ButtonRenderer;
