import {
  MdAttachMoney,
  MdCancel,
  MdOutlineDoneAll,
  MdTrendingDown,
  MdTrendingUp,
} from "react-icons/md";
import { ArrowDownCircleIcon, ArrowUpCircle } from "lucide-react";
import { MdBusiness } from "react-icons/md";
import { setIsBtnClick } from "components/PnlLineChart/PnlLineChartSlice";
import { setType } from "components/PnlLineChart/PnlLineChartSlice";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { setShowPnlChart } from "./PnlChartSlice";
const PnlChartIndividualTemplate = ({
  pnlChartData,
  type,
  pnlType,
  dispatch,
  options,
  handlePnlType,
  chartRef,
  CustomLegend,
  isBtnClick,
}) => {
  return (
    <>
      <header className="flex items-center justify-between font-bold text-2xl border-b-2 pb-2">
        <span className="ps-2 ">{`${pnlChartData?.tradingSymbol} ${type} PNL`}</span>
        <span className="text-red-500 cursor-pointer">
          <MdCancel onClick={() => dispatch(setShowPnlChart(false))} />
        </span>
      </header>
      <div className="p-2 pt-3">
        <div className="relative h-full px-2 py-4 border-[1px] rounded-xl shadow-md w-full">
          <h2 className="text-lg font-semibold text-center mb-4">
            PNL History
          </h2>

          <div className="flex-row -mt-4 mb-2">
            {pnlType === "realized" ? (
              <span
                class="inline-flex items-center rounded-md
                            bg-green-50 px-2 py-1 text-xs font-semibold text-green-700
                            ring-1 ring-inset ring-green-600/20 cursor-pointer"
                onClick={handlePnlType}
              >
                {pnlType.toLocaleLowerCase()} P&L
              </span>
            ) : (
              <span
                class="inline-flex items-center rounded-md
                            bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-700
                            ring-1 ring-inset ring-purple-600/20 cursor-pointer"
                onClick={handlePnlType}
              >
                {pnlType.toLocaleLowerCase()} P&L
              </span>
            )}
          </div>

          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={options}
            ref={chartRef}
          />

          <div className="flex justify-center mt-2">
            <CustomLegend />
          </div>
          <div className="absolute top-0 right-0 group p-2 h-[40px]">
            <button
              onClick={() => dispatch(setIsBtnClick(!isBtnClick))}
              className="bg-white border border-slate-200 rounded-md font-semibold text-sm p-2 inline-flex space-x-2 items-center w-24 z-20"
            >
              <svg
                class="w-7 h-7 text-indigo-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 10h16M8 14h8m-4-7V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"
                />
              </svg>
              <span className="mr-1 font-semibold">
                {type === "undefined" || !type ? "Type" : type}
              </span>
              {!isBtnClick ? (
                <ArrowDownCircleIcon size={24} />
              ) : (
                <ArrowUpCircle size={24} />
              )}
            </button>
            <ul
              className={`${
                isBtnClick ? "" : "hidden "
              } mt-1 top-full min-w-[80px] bg-white/90 border border-slate-200 p-2 rounded-lg shadow-lg`}
            >
              {["DTD", "WTD", "MTD", "YTD", "LTD"].map((value, index) => {
                return (
                  <li key={index}>
                    <p
                      className={`text-slate-800 ${
                        type === value ? " bg-slate-200 " : ""
                      }
                                                flex items-center p-2 text-sm`}
                      onClick={() => {
                        dispatch(setType(value));
                        dispatch(setIsBtnClick(false));
                      }}
                    >
                      <span className="whitespace-nowrap">{value}</span>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="pt-3">
          <div className="p-2 rounded-xl shadow-sm border-[1px]">
            <div className="p-2 ">
              <div className="font-bold pb-2 text-xl ">Details</div>
              <div className="grid grid-cols-2 ps-2">
                <div className="flex cols-span-1">
                  <span
                    style={{ backgroundColor: "rgba(3, 152, 245, 0.2)" }}
                    className="bg-blue-200 w-12 h-12 rounded-full text-md flex items-center justify-center"
                  >
                    <MdBusiness size={28} color="#0398f5" />
                  </span>
                  <div className="ps-2">
                    <p className="m-0 font-bold">Trading Symbol</p>
                    <p className="m-0 text-sm">{pnlChartData?.tradingSymbol}</p>
                  </div>
                </div>
                <div className="flex cols-span-1">
                  <span
                    style={{ backgroundColor: "rgba(3, 152, 245, 0.2)" }}
                    className="bg-blue-200 w-12 h-12 rounded-full text-md flex items-center justify-center"
                  >
                    <MdAttachMoney size={28} color="#0398f5" />
                  </span>
                  <div className="ps-2">
                    <p className="m-0 font-bold">Currency</p>
                    <p className="m-0 text-sm">{pnlChartData?.currency}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 pt-4">
              <div className="font-bold pb-2 text-xl ">PNL</div>
              <div className="grid grid-cols-2 ps-2">
                <div className="flex cols-span-1">
                  <span
                    style={{
                      backgroundColor:
                        pnlChartData?.realizedDTDPnl >= 0
                          ? "#DFF7E6"
                          : "#F6E6E6",
                    }}
                    className="bg-blue-200 w-12 h-12 rounded-full text-md flex items-center justify-center"
                  >
                    {/* {pnlChartData?.unRealizedDTDPnl >= 0 ? (
                          <MdOutlineDoneAll size={28} color="#67B684" />
                        ) : (
                          <MdOutlineDoneAll size={28} color="#D7535F" />
                        )} */}
                    <MdOutlineDoneAll
                      size={28}
                      color={
                        pnlChartData?.realizedDTDPnl >= 0
                          ? "#67B684"
                          : "#D7535F"
                      }
                    />
                  </span>
                  <div className="ps-2">
                    <p className="m-0 font-bold">Realized</p>
                    <p className="m-0 text-sm">
                      {pnlChartData?.realizedDTDPnl} {pnlChartData?.currency}
                    </p>
                  </div>
                </div>
                <div className="flex cols-span-1">
                  <span
                    style={{
                      backgroundColor:
                        pnlChartData?.unRealizedDTDPnl >= 0
                          ? "#DFF7E6"
                          : "#F6E6E6",
                    }}
                    className=" w-12 h-12 rounded-full text-md flex items-center justify-center"
                  >
                    {pnlChartData?.unRealizedDTDPnl >= 0 ? (
                      <MdTrendingUp size={28} color="#67B684" />
                    ) : (
                      <MdTrendingDown size={28} color="#D7535F" />
                    )}
                  </span>
                  <div className="ps-2">
                    <p className="m-0 font-bold">Unrealized</p>
                    <p className="m-0 text-sm">
                      {pnlChartData?.unRealizedDTDPnl} {pnlChartData?.currency}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Card> */}
      </div>
    </>
  );
};
export default PnlChartIndividualTemplate;
