/*eslint-disable*/
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Spinner, Alert } from "reactstrap";
import { CiEdit } from "react-icons/ci";
import { TbPencilCancel } from "react-icons/tb";
//import OrderForm from '../OrderForm/Field/OrderForm';
import { useGlobalState } from "components/globalVariable";
import { ShimmerTable } from "react-shimmer-effects";
import OrderForm from "components/OrderForm/Field/OrderForm";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TableRefresher from "components/TableRefreshComponet/TableRefresher";
import OrderStatusCellRender from "./OrderStatusCellRender";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateData } from "components/OrderForm/Field/orderFormSlice";
import { DraggableComponent } from "components/OrderForm/Field/draggable";
import { setIsUpdateForm } from "components/OrderForm/Field/orderFormSlice";
import API_ENDPOINTS from "services/api";
import {
  selectBroker,
  selectAccount,
  selectStrategy,
} from "reducers/BrokerSlice.js";
import { setOrderStrategyId } from "reducers/FilterSlice";
import { getProductTypeandLots } from "components/PositionsGrid/container/getProductType";
import { apiConfig } from "apiConfig";
import { useFetchData } from "utils/customFetch";
import "../../styles/agGridStyles.css";
function OrderTable() {
  const { fetchData } = useFetchData();
  const [clientId] = useGlobalState("clientId");
  const [accountIds] = useGlobalState("accountIds");
  const [clientName] = useGlobalState("clientName");
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [count, setCount] = useState(1);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const showOrderResponse = useSelector(
    (state) => state.orderForm.showOrderResponse
  );
  const [refreshTime, setRefreshTime] = useState(
    new Date().toLocaleTimeString([], { hour12: true }).replace("pm", "PM")
  );
  const updateData = useSelector((state) => state.orderForm.updateData);
  const { dates, broker, security, portfolio, entity, strategy, zenSecIds } =
    useSelector((state) => state.filter);
  const strategyList = useSelector((state) => state.filter.strategyList);
  const refreshTriggerCount = useSelector(
    (state) => state.orderForm.refreshTriggerCount
  );
  const secData = useSelector((state) => state.broker.securityList);
  const [formOpen, setFormOpen] = useState(false);
  const dateConversion = (val) => {
    const dateObj = new Date(val);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const orderExecutionTimeGetter = (params) => {
    const rowData = params.api.rowModel.rowsToDisplay.map((row) => row.data);
    if (rowData) {
      rowData.sort(
        (a, b) =>
          new Date(b.brokerTimestamp).getTime() -
          new Date(a.brokerTimestamp).getTime()
      );
    }
    return params.value.replace("T", " ").slice(0, 19);
  };

  const editCellRenderer = (params) => {
    const handleIconClick = () => {
      const rowData = params.data;
      const fetchLatestOrder = async () => {
        try {
          const data = await fetchData(
            `${API_ENDPOINTS.GET_LATEST_ORDER()}?zenOrderId=${
              rowData?.zenOrderId
            }`
          );
          dispatch(setIsUpdateForm(true));
          const { securityType, lotSize } = getProductTypeandLots(
            rowData?.positionCompKey?.zenSecId,
            secData
          );
          dispatch(
            setUpdateData({
              ...data,
              zenId: rowData?.positionCompKey?.zenSecId,
              securityType: securityType,
              lotSize: lotSize,
            })
          );
          setFormOpen(true);
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        }
      };
      fetchLatestOrder();
      //I need to make reques to getorders and find order details for zenorderid
    };

    if (
      params.data.status === "OPEN" ||
      params.data.status === "PENDING" ||
      params.data.status === "TRIGGER_PENDING"
    ) {
      return (
        <CiEdit
          style={{
            cursor: "pointer",
            fontSize: "22px",
            minWidth: "12px",
            minHeight: "12px",
            display: "inline-block",
          }}
          onClick={handleIconClick}
        />
      );
    } else {
      return (
        <TbPencilCancel
          style={{
            cursor: "not-allowed",
            fontSize: "22px",
            color: " rgb(192, 190, 190)",

            minWidth: "12px",
            minHeight: "12px",
            display: "inline-block",
          }}
        />
      );
    }
  };

  // const onFirstDataRendered = (params) => {
  //   // params.columnApi.autoSizeColumns(autoSizeColumns, {
  //   //   autoSizeStrategy: { type: "fitGridWidth" },
  //   // });
  //   params.columnApi.autoSizeColumns(params.columnApi.getAllColumns(), {
  //     autoSizeStrategy: { type: "fitGridWidth" }, // Fit to table width
  //   });
  //   params.api.sizeColumnsToFit(); // Ensures columns fit the available width
  // };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  // Run when rowData updates
  useEffect(() => {
    if (gridRef.current && rowData?.length) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [rowData]);
  const autoSizeStrategy = useMemo(
    () => ({
      type: "fitGridWidth", // This ensures columns resize to fit grid width
    }),
    []
  );

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current) {
        gridRef.current.api.sizeColumnsToFit();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const columnDefs = [
    {
      headerName: "",
      field: "edit",
      headerClass: "align-left",
      cellClass: "align-left ",
      cellStyle: { textAlign: "left" },
      cellRenderer: editCellRenderer,
      width: 50,
      flex: 1,
      editable: false,
    },
    {
      headerName: "Trading Symbol",
      field: "tradingSymbol",
      headerClass:
        "align-left custom-header-padding left-aligned-header-padding",
      cellClass: "left-aligned-cell-padding",
      flex: 1,
      minWidth: 130,
    },
    {
      headerName: "Broker",
      field: "positionCompKey.broker",
      headerClass:
        "align-left custom-header-padding left-aligned-header-padding",
      cellClass: "left-aligned-cell-padding",
      minWidth: 110,
      flex: 1,
    },
    {
      headerName: "Strategy Name",
      field: "strategyLists",
      headerClass:
        "align-left custom-header-padding left-aligned-header-padding",
      cellClass: "left-aligned-cell-padding",
      minWidth: 120,
      flex: 1,
      sort: null,
      valueGetter: (params) => {
        const strategyId = params?.data?.positionCompKey?.strategyId;
        return strategyId && strategyList
          ? strategyList.filter((str) => str.strategy_id === strategyId)[0]
              ?.strategy_name
          : "";
      },
    },

    {
      headerName: "Status",
      field: "status",
      headerClass: "custom-header-padding ag-center-aligned-header",
      // cellClass: "align-left",
      cellStyle: { justifyContent: "center", padding: "0" },
      cellRenderer: OrderStatusCellRender,
      minWidth: 120,
      flex: 1,
    },
    // {
    //   headerName: "Message", field: 'message',
    //   headerClass: 'align-left',
    //   cellClass: 'align-left',
    //   cellStyle:{textAlign:"left"},
    //   width:100,
    //   flex:1

    // },
    {
      headerName: "OrderResponse Time",
      field: "brokerTimestamp",
      minWidth: "150",
      headerClass:
        "align-left custom-header-padding left-aligned-header-padding",
      cellClass: "left-aligned-cell-padding",
      valueFormatter: orderExecutionTimeGetter,
      sort: "desc",
      flex: 1,
    },
    {
      headerName: "ZenOrder Id",
      field: "zenOrderId",
      headerClass: "ag-right-aligned-header",
      flex: 1,
      minWidth: 130,
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 20px 0 0",
      },
      headerComponentParams: {
        style: { padding: "0 20px 0 0 !important" },
      },
    },
  ];

  // const onGridReady = (params) => {
  //   params.api.sizeColumnsToFit(); // Ensures columns take full width
  // };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      editable: true,
      filter: true,
    }),
    []
  );

  var cellClickedListener = () => {
    setCount(count + 1);
  };

  const [isFetching, setIsFetching] = useState(true);
  const {
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
    selectedAccount,
  } = useSelector((state) => state.clientSelection);
  useEffect(() => {
    setIsFetching(true);
    const body = {
      positionCompKeyFilter: {
        clientId: clientId,
        ...(selectedAccount === "all" ||
        selectedAccount === "" ||
        selectedAccount === null ||
        selectedAccount === undefined
          ? {}
          : { accountIds: [selectedAccount] }),
        ...(zenSecIds === null ||
        zenSecIds === undefined ||
        zenSecIds.length === 0
          ? {}
          : { zenSecIds: zenSecIds }),
        // Conditionally include strategyIds only if strategy is not empty
        ...(selectedStrategy === "all" ||
        selectedStrategy === "" ||
        selectedStrategy === null ||
        selectedStrategy === undefined
          ? {}
          : { strategyIds: [selectedStrategy] }),
        ...(selectedBroker === "all" ||
        selectedBroker === "" ||
        selectedBroker === null ||
        selectedBroker === undefined
          ? {}
          : { brokers: [selectedBrokerName] }), // Only include brokers if broker is not "ALL"
      },
    };
    const fetchDataCall = async () => {
      try {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const rowData = await fetchData(
          // apiConfig.THOR_URL + "order/getZenOrderState",
          API_ENDPOINTS.GET_ZEN_ORDER_STATE(),
          "POST",
          header,
          body
        );
        setRowData(Array.from(rowData));

        setTimeout(() => {
          if (gridRef.current) {
            gridRef.current.api.sizeColumnsToFit(); // Re-fit columns after data update
          }
        }, 100);

        setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
        setTimeout(() => {
          setIsFetching(false);
        }, 750);
        setIsError(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
        setErrorMsg(error);
      }
    };
    if (dates[1]) fetchDataCall();

    const intervalId = setInterval(fetchDataCall, 60000);
    //setIntervel cleaning process
    return () => {
      clearInterval(intervalId);
    };
  }, [
    dates,
    refreshTriggerCount,
    count,
    clientId,
    zenSecIds,
    selectedAccount,
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
  ]);

  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);
  const gridStyle = useMemo(() => ({ height: "68vh", width: "93vw" }), []);
  useEffect(() => {
    if (gridRef.current) gridRef.current.api.sizeColumnsToFit();
  }, [formOpen, showOrderResponse, updateData]);

  return (
    <>
      {formOpen && (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            display: "flex",
            zIndex: 100,
          }}
        >
          <DraggableComponent>
            <OrderForm
              trade={updateData?.transactionType}
              setFormOpen={(value) => setFormOpen(!value)}
              hideForm={() => setFormOpen(false)}
            />
          </DraggableComponent>
        </div>
      )}
      <header
        className="flex items-center p-2 justify-between"
        style={{ backgroundColor: "#e5ebf2" }}
      >
        <span className="text-sm font-semibold font-sans">Orders</span>

        <div className="pl-1">
          <TableRefresher
            cellClickedListener={cellClickedListener}
            showSpinner={isFetching}
          />
        </div>
      </header>
      <div style={gridStyle} className="ag-theme-alpine">
        {isError ? <Alert color="danger">{errorMsg}</Alert> : ""}
        {isFetching ? (
          <ShimmerTable row={4} col={4} />
        ) : (
          <AgGridReact
            localeText={{
              noRowsToShow:
                "No orders placed yet. Start trading by placing a buy or sell order!",
            }}
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            gridOptions={{
              autoSizeStrategy: autoSizeStrategy, // Ensure it's passed
            }}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            // paginationPageSize={10}
            animateRows={true}
            autoSizeStrategy={autoSizeStrategy}
            suppressDragLeaveHidesColumns={true}
            domLayout="normal"
          />
        )}
      </div>
    </>
  );
}

export default OrderTable;
